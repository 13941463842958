.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

/*--- NEW -- */

.width100Percent {
  width: 100%;
}

.banner {
  width: 100%;
  height: 50px;
  background-color: #b0db25;
  margin-bottom: 30px;
  text-align: center;
  font-size: larger;
  font-weight: bolder;
  padding-top: 10px;
}

.loading {
  font-size: xx-large;
  text-align: center;
  color: #0a357a;
}

.loadingSmall {
  font-size: large;
  text-align: center;
  color: #0a357a;
}

.textLarger {
  font-size: larger;
  cursor: pointer;
  font-weight: bolder;
}

.cursorHand {
  cursor: pointer;
}

.textSmaller {
  font-size: smaller;
}

.menu-tree {
  margin-left: 10px;
  font-size: 12pt;
  cursor: pointer;
}

.centerImage {
  margin-left: 50px !important;
  margin-top: 0px !important;
}

.readOnly {
  background-color: lightgray;
}

.downaBit {
  margin-top: 15px;
  margin-right: 50px;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.topDown {
  top: 60px !important;
  height: 60px;
}

.paddingTop {
  margin-top: 20px;
}

.minWidth {
  min-width: 378px !important;
  width: 100%;
  float: right !important;
  padding-left: 20px;
}

.width100Percent {
  width: 100%;
  height: 55px;
}

.screenView {
  height: 80vh;
}

.menuDown {
  margin-top: 50px;
}

.green {
  color: green;
}

.red {
  color: red;
}

.blue {
  color: darkblue;
}

.loginScreen {
  background-color: #0a357a;
  color: white;
  height: 100%;
}

.btn-green {
  color: #fff;
  background-color: #b0db25;
  border-color: #263238;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-dark:hover {
  color: #fff;
  background-color: #171e21;
  border-color: #11171a;
}

.btn-dark:focus,
.btn-dark.focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08),
    0 0 0 0.2rem rgba(38, 50, 56, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #263238;
  border-color: #263238;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #11171a;
  border-color: #0c1012;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125),
    0 0 0 0.2rem rgba(38, 50, 56, 0.5);
}

.topbar {
  height: 96px;
}

.nav-link {
  height: 40px !important;
  line-height: 40px !important;
}

.btn {
  height: 35px;
  top: 15px;
  padding-top: 0px;
}

.btnNoTop {
  height: 35px;
  top: 0px;
  padding-top: 0px;
}

.btnActions {
  width: 60px;
  font-size: 9pt;
}

.btnActionsSmall {
  width: 50px;
  font-size: 6pt;
}

.btnActionsTop {
  margin-right: 0px;
}

.btnLongAction {
  width: 75px;
}

.badgePoint {
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background-color: #fc4b6c;
  position: absolute;
  left: 5px;
  top: -25px;
}

.buttonUp {
  top: 0px;
}

.page-wrapper {
  margin-left: 0px;
  padding-top: 20px !important;
}

.navHeight {
  height: 70px;
}

.navbar-brand {
  height: 60px;
  padding: 0px;
}

.logo-item {
  height: 60px;
}

.nav-item {
  height: 70px;
  padding-top: 0px !important;
}

.navbar-nav {
  height: 70px;
}

.nav-link {
  height: 40px !important;
  line-height: 40px !important;
}

.logo-text {
  height: 60px;
}

.topbar {
  min-height: 70px !important;
}

.topbar .top-navbar {
  min-height: 70px !important;
  padding: 0px;
}

.navbar-header {
  height: 70px;
}

.btnNotify {
  width: 40px;
  top: 0px;
  text-align: center;
}

.menuText {
  padding-left: 5px;
  font-family: "Poppins";
  font-size: small;
}

#main-wrapper {
  overflow: visible;
}

.page-content {
  height: auto;
  padding-top: 70px !important;
}

textarea {
  resize: none;
}

.summaryValueText {
  font-size: normal;
}

.shornLabel {
  width: 80px;
}

.summaryLabel {
  font-size: normal;
  font-weight: bold;
}

.rowHeight {
  margin-bottom: 10px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 20px;
  margin-top: 10px;
  border-radius: 5px;
  margin-left: 20px;
}

.homelabel {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 10px;
  margin-top: 30px;
  border-radius: 5px;
  margin-left: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0a357a;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 5px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 5px;
}

input:checked + .slider {
  background-color: #b0db25;
}

input:focus + .slider {
  box-shadow: 0 0 1px #b0db25;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round:before {
  border-radius: 50%;
}

.menuToggle {
  color: #0a357a;
  padding: 10px;
  height: 20px;
  vertical-align: middle;
  padding-top: 5px;
}

input {
  font-size: initial;
}

.logout {
  display: inline-block;
}

body {
  color: black !important;
  background-color: #eef5f9;
  font-size: 10pt;
}

label {
  color: black;
  font-size: 10pt;
  font-weight: bold;
}

.btn {
  font-size: 10pt;
}

.treeWidth {
  width: 270px;
  margin-left: 10px;
}

.sidebar-nav ul {
  padding-top: 5px;
}

.col-md-4 {
  color: black;
  font-weight: bold;
}

.card .card-title {
  font-weight: bold;
  font-size: 12pt;
}

.moveDown10 {
  margin-top: 0px;
  float: right;
}

.navMenu {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 15px;
}

.nav-toggler {
  padding-left: 3px !important;
}

.nav-item {
  padding-top: 5px !important;
}

.nav-link {
  padding-top: 5px !important;
}

.menuTopButtons {
  /* background-color: blue; */
  width: 100%;
}

.menuLeft {
  width: 200px !important;
}

.btnActionsDelete {
  width: 90px;
}

.textView {
  white-space: pre-wrap;
}

.mainTitle {
  border-left-color: white !important;
  border-left-width: 0px;
  border-left-style: solid;
  border-right-style: hidden;
  border-top-style: hidden;
  border-bottom-color: white;
  border-bottom-width: 0px;
  border-bottom-style: solid;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.nearTop {
  margin-top: 0px !important;
}

.whiteBack {
  background-color: white !important;
}

/*******************
 Footer
*******************/

.footer {
  padding: 15px 20px;
}

.redBack {
  background-color: red !important;
}

.greenBack {
  background-color: green !important;
}

.noTop {
  top: 3px !important;
}

.noTop4 {
  top: 7px !important;
}

.btnNoLeft {
  left: 0px !important;
}

.menuTop {
  height: 35px;
  top: 15px;
  padding-top: 0px;
}

.tableCells {
  font-weight: normal !important;
  font-size: 10pt !important;
  padding-left: 0px !important;
}

.tableContainer {
  overflow-x: scroll;
  overflow-y: scroll;
}

.ReactTable .rt-thead {
  overflow-y: scroll;
}

.navHeaderBg {
  background-color: #0a357a !important;
}

.navWhiteColor {
  color: white !important;
}

.navRedBg {
  background-color: #fc4b6c !important;
}

.navBlackBg {
  color: white !important;
  background-color: #0a357a;
}

.navBlueColor {
  color: #0a357a;
}
